import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { SliderItem } from "../../../../shared/slider/slider-item/SliderItem";
import { Slider } from "../../../../shared/slider/Slider";
import Lr_Pdf from "../../../../core/assets/pdf/lr-pdf-first.pdf";
import { AnimateComponentOnScroll } from "../../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: "#FFF",
    padding: "10vh 10vw 0",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: "5vh 10vw",
    },
  },
  title: {
    width: "100%",
    textAlign: "center",
  },
  subTitle: {
    width: "100%",
    padding: "20px 15% 80px",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "20px 25px 0",
    },
  },
  sliderBox: {
    padding: "0 10vw 10vh",
    backgroundColor: "#FFF",
  },
}));

interface ResearchSectionProps {}

export const ResearchSection: React.FC<ResearchSectionProps> = () => {
  const { classes } = useStyles();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const sliderItems = (
    <>
      <SliderItem
        title="Chainlink node operations"
        image={"research"}
        showBorder
        link={Lr_Pdf}
      />
    </>
  );

  return (
    <>
      <Box id="research" className={classes.root}>
        <Typography
          className={classes.title}
          sx={{ typography: { sm: "h3", xs: "h8" } }}
        >
          Research
        </Typography>
        <Typography
          className={classes.subTitle}
          sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
        >
          Because knowledge is power
        </Typography>
      </Box>

      <AnimateComponentOnScroll enterDirection="bottom">
        <Box className={classes.sliderBox}>
          <Slider
            id="scroll-research"
            items={sliderItems}
            isDisabled
            forceDisable
            height={screenWidth < 600 ? "400px" : "520px"}
          />
        </Box>
      </AnimateComponentOnScroll>
    </>
  );
};
