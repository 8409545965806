/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import axios from "axios";

const useStyles = makeStyles()((theme) => ({
  achievementsSection: {
    display: "flex",
    flexWrap: "wrap",
    background: "#F5F5F5",
    padding: "50px 0 0",
    overflow: "hidden",
    color: theme.palette.primary.main,
    height: "660px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  header: {
    margin: "0 25px",
    padding: "0 7px",
    marginBottom: "2vh",
    background: "#FFF",
    borderRadius: "12px",
  },
  title: {
    margin: "0 25px",
    width: "100%",
  },
  statSection: {
    textAlign: "center",
    position: "relative",
    height: "60vh",
    marginBottom: "10vh",
    color: theme.palette.primary.contrastText,
  },
  dataFeed: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "220px",
    height: "220px",
    alignContent: "center",
    background: "#50BCA4",
    position: "absolute",
    borderRadius: "50%",
    zIndex: "2",
    top: "250px",
    left: "140px",
  },
  networks: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: "185px",
    height: "185px",
    background: "#42A0AA",
    position: "absolute",
    borderRadius: "50%",
    zIndex: "2",
    top: "30px",
    left: "210px",
  },
  transactions: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    width: "300px",
    height: "300px",
    position: "absolute",
    background: "#246478",
    borderRadius: "50%",
    zIndex: "0",
    top: "80px",
    left: "-40px",
  },
}));

interface AchievementsSectionMProps {}

export const AchievementsSectionM: React.FC<AchievementsSectionMProps> = () => {
  const { classes } = useStyles();

  const [statistics, setStatistics] = useState({
    dataFeed: 0,
    networks: 0,
    transactions: 0,
  });

  useEffect(() => {
    if (statistics.dataFeed === 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/GetStatistics`)
        .then((res) => {
          const result = {
            dataFeed: res.data[0].unique_Data_Feeds,
            networks: res.data[0].supported_Networks,
            transactions: res.data[0].total_Transactions,
          };

          setStatistics(result);
        });
    }
  }, []);

  const delay = 1500;
  const { dataFeed, networks, transactions } = statistics;

  let dataFeedTimer: any;
  let networksTimer: any;
  let transactionsTimer: any;

  const dataFeedTime = Math.floor(delay / dataFeed);
  const networksTime = delay / networks;
  const transactionsTime = delay / transactions;

  const [startCounting, setStartCounting] = useState(false);
  const [dataFeedCounter, setDataFeed] = useState(0);
  const [networksCounter, setNetworks] = useState(0);
  const [transactionsCounter, setTransactions] = useState(0);

  const getCounter = (
    timer: any,
    time: number,
    data: number,
    setData: (arg0: (prev: any) => any) => void,
    counter: number
  ) => {
    if (!startCounting) return;
    clearInterval(timer);
    timer = setInterval(() => {
      if (counter >= data) {
        clearInterval(timer);
        return;
      }
      setData((prev) => prev + 1);
    }, time);

    return () => clearInterval(timer);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (1050 <= window.pageYOffset && window.pageYOffset <= 1900) {
          setStartCounting(true);
        } else {
          setStartCounting(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    return getCounter(
      dataFeedTimer,
      dataFeedTime,
      dataFeed,
      setDataFeed,
      dataFeedCounter
    );
  }, [dataFeedCounter, startCounting]);

  useEffect(() => {
    return getCounter(
      networksTimer,
      networksTime,
      networks,
      setNetworks,
      networksCounter
    );
  }, [networksCounter, startCounting]);

  useEffect(() => {
    return getCounter(
      transactionsTimer,
      transactionsTime,
      transactions,
      setTransactions,
      transactionsCounter
    );
  }, [transactionsCounter, startCounting]);

  return (
    <div className={classes.achievementsSection}>
      <Typography className={classes.header} variant={"body6"}>
        Achievements
      </Typography>
      <Typography className={classes.title} variant={"h8"}>
        Our contribution to an increasingly decentralized world
      </Typography>
      <div className={classes.statSection}>
        <div className={classes.dataFeed}>
          <Typography sx={{ width: "100%" }} variant={"statNumber2"}>
            {dataFeedCounter}
          </Typography>
          <Typography variant={"subtitle5"}>
            Unique <br />
            data feeds
          </Typography>
        </div>
        <div className={classes.networks}>
          <Typography sx={{ width: "100%" }} variant={"statNumber2"}>
            {networksCounter}
          </Typography>
          <Typography variant={"subtitle5"}>
            Supported <br />
            networks
          </Typography>
        </div>
        <div className={classes.transactions}>
          <Typography
            sx={{ width: "100%" }}
            variant={"statNumber2"}
          >{`${transactionsCounter}M +`}</Typography>
          <Typography variant={"subtitle5"}>
            On-chain <br />
            transactions
          </Typography>{" "}
        </div>
      </div>
    </div>
  );
};
