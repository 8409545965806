import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, List, Box } from "@mui/material";

const useStyles = makeStyles<void, "dropdownContent">()(
  (theme, _params, classes) => ({
    dropdownButton: {
      position: "relative",
      backgroundColor: "transparent",
      fontSize: "25px",
      fontWeight: "500",
      lineHeight: "60px",
      textTransform: "none",
      "&:hover": {
        textTransform: "none",
        backgroundColor: "transparent",
        [` .${classes.dropdownContent}`]: {
          display: "flex",
          position: "absolute",
          flexDirection: "column",
          width: "300px",
          padding: "30px 20px",
          borderRadius: "6px",
        },
      },
    },
    dropdownContent: {
      display: "none",
      position: "absolute",
      top: "60px",
      backgroundColor: "#FFF",
      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
      zIndex: "1",
    },
    triangle: {
      content: "''",
      position: "absolute",
      top: "-10px",
      borderStyle: "solid",
      borderWidth: "0 18px 18px 18px",
      borderColor: "#FFFFFF transparent",
      display: "block",
      width: "0",
      zIndex: "0",
    },
  })
);

interface DropdownListProps {
  link: string;
  title: string;
  transparent: boolean;
  children: React.ReactNode;
  reversed?: boolean;
  target?: string;
}

export const DropdownList: React.FC<DropdownListProps> = ({
  link,
  title,
  transparent,
  reversed = false,
  children,
  target,
}) => {
  const { classes } = useStyles();

  return (
    <Box
      sx={{
        color: transparent
          ? (theme) => theme.palette.primary.contrastText
          : "#005AAA",
      }}
      className={classes.dropdownButton}
    >
      <Button
        sx={{
          color: transparent
            ? (theme) => theme.palette.primary.contrastText
            : "#005AAA",
        }}
        className={classes.dropdownButton}
        href={link}
        disableRipple
        target={target ?? "_self"}
      >
        {title}
      </Button>
      <List
        sx={{ right: reversed ? "10px" : "", left: reversed ? "" : "10px" }}
        className={classes.dropdownContent}
      >
        <div
          style={{
            right: reversed ? "20px" : "",
            left: reversed ? "" : "20px",
          }}
          className={classes.triangle}
        ></div>
        {children}
      </List>
    </Box>
  );
};
