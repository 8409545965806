import React, { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  leftSegment: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
  },
  rightSegment: {
    width: "100%",
    display: "flex",
    justifyContent: "end",
  },
  left: {
    display: "flex",
    backgroundColor: theme.palette.background3,
    marginTop: "35px",
    borderRadius: "0 295px 295px 0",
    padding: "35px",
    paddingLeft: "10%",
  },
  right: {
    display: "flex",
    backgroundColor: theme.palette.background3,
    marginTop: "35px",
    borderRadius: "295px 0 0 295px",
    padding: "35px",
    paddingRight: "10%",
  },
  textLeft: {
    textAlign: "right",
    width: "40vw",
    justifyContent: "start",
    margin: "auto 0",
  },
  textRight: {
    textAlign: "left",
    width: "40vw",
    justifyContent: "end",
    margin: "auto 0",
  },
  title: {
    color: theme.palette.background2,
  },
  text: {
    color: theme.palette.primary.light,
  },
  image: {
    margin: "auto 85px",
    maxHeight: "300px",
    maxWidth: "300px",
  },
  button: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.background2,
    height: "70px",
    minWidth: "260px",
    borderRadius: "43px",
    textTransform: "none",
    padding: "0 36px 6px 36px",
    margin: "auto 85px",
  },
}));

interface ServiceSectionSegmentProps {
  title: string;
  image: string;
  link: string;
  newSide?: boolean;
  reversed?: boolean;
  children: ReactNode;
}

export const ServiceSectionSegment: React.FC<ServiceSectionSegmentProps> = ({
  title,
  image,
  link,
  newSide,
  reversed,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <div className={reversed ? classes.leftSegment : classes.rightSegment}>
      <div className={reversed ? classes.left : classes.right}>
        {!reversed ? (
          <img
            className={classes.image}
            src={`images/${image}.png`}
            alt={"service"}
          />
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            href={link}
            target={newSide ? "_blank" : ""}
          >
            <Typography variant={"button1"}>Learn more</Typography>
          </Button>
        )}
        <div className={reversed ? classes.textLeft : classes.textRight}>
          <Typography className={classes.title} variant={"h4"}>
            {title}
          </Typography>
          <Typography className={classes.text} variant={"subtitle1"}>
            {children}
          </Typography>
        </div>
        {reversed ? (
          <img
            className={classes.image}
            src={`images/${image}.png`}
            alt={"service"}
          />
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            href={link}
            target={newSide ? "_blank" : ""}
          >
            <Typography variant={"button1"}>Learn more</Typography>
          </Button>
        )}
      </div>
    </div>
  );
};
