import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  background: {
    background: theme.palette.common.white,
    width: "20%",
    borderRadius: "15px",

    [theme.breakpoints.down("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.down("lg")]: { width: "35%" },

    [theme.breakpoints.down("md")]: { width: "50%" },

    [theme.breakpoints.down("sm")]: { width: "80%" },
  },
  title: {
    margin: "35px 0",
    display: "flex",
    justifyContent: "center",
    fontSize: "27px",
    fontWeight: 700,
  },
  description: {
    margin: "0 55px",
    padding: "25px 10px 50px",
    borderTop: "1px solid #D3D7DB",
    display: "flex",
    justifyContent: "center",
    fontSize: "21px",
    fontWeight: 500,
    lineHeight: 1.2,
    height: "80px",
    textAlign: "center",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "80px",
  },
  button: {
    background: "linear-gradient(94.25deg, #DC5203 0%, #DC8903 110.52%)",
    borderRadius: "42.5px",
    textTransform: "none",
    width: "55%",
    color: theme.palette.common.white,
    fontSize: "22px",
    fontWeight: 500,

    "&:hover": {
      background:
        "linear-gradient(97.51deg, #F88702 -0.88%, #FECE01 96.18%), #FFFFFF",
    },
  },
}));

interface SubPageSegmentProps {
  title: string;
  description: string;
  link: string;
}

export const SubPageSegment: React.FC<SubPageSegmentProps> = ({
  title,
  description,
  link,
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.background}>
      <h2 className={classes.title}>{title} </h2>
      <Typography className={classes.description}>{description} </Typography>
      <Box className={classes.buttonBox}>
        <Button className={classes.button} href={link}>
          Get started now
        </Button>
      </Box>
    </Box>
  );
};
