import { Typography, Box } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { Slider } from "../../../../shared/slider/Slider";
import { SliderItem } from "../../../../shared/slider/slider-item/SliderItem";
import { AnimateComponentOnScroll } from "../../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: "#F5F5F5",
    padding: "10vh 10vw 0",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: "5vh 10vw 0",
    },
  },
  title: {
    width: "100%",
    textAlign: "center",
  },
  subTitle: {
    width: "100%",
    padding: "20px 15% 80px",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "20px 0 5vh 0",
    },
  },
  sliderBox: {
    width: "100%",
    padding: 0,
    paddingBottom: "10vh",

    [theme.breakpoints.down("lg")]: {
      paddingBottom: "10vh",
    },
  },
}));

interface GithubSectionProps {}

export const GithubSection: React.FC<GithubSectionProps> = () => {
  const { classes } = useStyles();

  const sliderItems = (
    <>
      <SliderItem
        imageTitle="Chainlink Node Docker Deployment"
        showBorder
        link="https://github.com/Linkriver/Chainlink-Node-Docker-Deployment"
      />
      <SliderItem
        imageTitle="NodeLogs Page Content"
        showBorder
        link="https://github.com/Linkriver/nodelogs-content"
      />
      <SliderItem
        imageTitle="Chainlink Node Requester Whitelisting"
        showBorder
        link="https://github.com/Linkriver/Chainlink-Node-Whitelisting"
      />
    </>
  );

  return (
    <Box id="github" className={classes.root}>
      <Typography
        className={classes.title}
        sx={{ typography: { sm: "h3", xs: "h8" } }}
      >
        GitHub
      </Typography>
      <Typography
        className={classes.subTitle}
        sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
      >
        Find helpful deployment guides and simple tools related to Chainlink
        node operations.
      </Typography>
      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <Box className={classes.sliderBox}>
          <Slider
            id="scroll-github"
            items={sliderItems}
            height="330px"
            isDisabled={window.innerWidth > 1200}
          />
        </Box>
      </AnimateComponentOnScroll>
    </Box>
  );
};
