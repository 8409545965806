import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const useStyles = makeStyles<{ gradient: GradientProps; height: string }>()(
  (theme, { gradient, height }) => ({
    backgroundAnimation: {
      width: "100%",
      height: height,
      overflow: "hidden",
      background: `linear-gradient(to right, #000, ${gradient[1]}, ${gradient[2]}, ${gradient[3]}, ${gradient[1]}, #000) no-repeat, 
            radial-gradient(ellipse at top right, #41b496 0%,#0a77d5 34%,#73b1e7 69%,${gradient[1]} 69%,#539fe1 89%,#539fe1 89%,#87bcea 97%),
            radial-gradient(ellipse at bottom, #4c4c4c80 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%)`,
      animation: "background-animation 11s ease infinite",
      backgroundSize: "200% 100%",
      "@keyframes background-animation": {
        "0%": {
          backgroundPosition: "0% 100%",
        },
        "50%": {
          backgroundPosition: "100% 50%",
        },
        "100%": {
          backgroundPosition: "0% 100%",
        },
      },
    },
    gradient: {
      position: "relative",
      height: height,
      width: "100%",
      background: `linear-gradient(to left top, ${gradient[30]}, ${gradient[20]}, ${gradient[0]} 50%, ${gradient[20]}, ${gradient[10]}, ${gradient[30]}, ${gradient[1]}), 
            linear-gradient(to bottom, ${gradient[20]}, ${gradient[0]} 50%, ${gradient[1]}, ${gradient[10]}, ${gradient[30]}),
            linear-gradient(to top, ${gradient[20]}, ${gradient[0]} 50%, ${gradient[1]}, ${gradient[10]}, ${gradient[30]})`,
      animation: "gradient-animation 15s ease infinite",
      backgroundSize: "150% 150%",
      "@keyframes gradient-animation": {
        "0%": {
          backgroundPosition: "0% 100%",
        },
        "50%": {
          backgroundPosition: "100% 50%",
        },
        "100%": {
          backgroundPosition: "0% 100%",
        },
      },
    },
    centered: {
      display: "flex",
      alignContent: "center",
      justifyItems: "center",
      flexWrap: "wrap",
      position: "absolute",
      top: "0",
      height: height,
      width: "100%",
      color: theme.palette.primary.contrastText,
    },
    title: {
      fontSize: "45px",
      fontWeight: 600,
      textAlign: "center",
      justifyContent: "center",
      display: "flex",

      margin: 0,
      padding: "0 10vw",
      width: "100%",

      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
        fontWeight: 700,
        textAlign: "left",
        justifyContent: "left",
      },
    },
    subTitle: {
      display: "flex",
      padding: "0 10vw",
      width: "100%",
      marginTop: "25px",
      textAlign: "center",
      justifyContent: "center",
      fontSize: "25px",
      fontWeight: 500,

      [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
        textAlign: "left",
        justifyContent: "left",
        fontSize: "18px",
        fontWeight: 500,
      },
    },
    icon: {
      display: "flex",
      margin: "auto",
      position: "relative",
      top: "-10vh",
      width: "50px",
      height: "50px",
      color: "#FFF",
      [theme.breakpoints.up("sm")]: { display: "none" },
    },
    button: {
      margin: "25px 10vw",
      backgroundColor: "#FFF",
      width: "120px",
      height: "30px",
      paddingBottom: "8px",
      borderRadius: "21px",
      textTransform: "none",
      ...theme.typography.body5,
      "&:hover": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("sm")]: { display: "none" },
    },
  })
);

interface GradientProps {
  0: string;
  1: string;
  2: string;
  3: string;
  10: string;
  20: string;
  30: string;
}

interface LandingSectionProps {
  title: string;
  gradient: GradientProps;
  subTitle?: string;
  height?: string;
}

export const LandingSection: React.FC<LandingSectionProps> = ({
  gradient,
  title,
  subTitle,
  height = "100vh",
}) => {
  const { classes } = useStyles({ gradient, height });

  return (
    <div className={classes.backgroundAnimation}>
      <div className={classes.gradient} />
      <div className={classes.centered}>
        <h1 className={classes.title}>{title}</h1>
        <h2 className={classes.subTitle}>{subTitle}</h2>
        <Button
          onClick={() => {
            window.scrollTo(0, window.innerHeight);
            window.scrollTo(0, window.innerHeight);
          }}
          className={classes.button}
        >
          Get started
        </Button>
      </div>
      <KeyboardArrowDownIcon
        onClick={() => {
          window.scrollTo(0, window.innerHeight);
          window.scrollTo(0, window.innerHeight);
        }}
        className={classes.icon}
      />
    </div>
  );
};
