import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { ContactFormSegment } from "./segments/ContactFormSegment";
import { ContactTextSegment } from "./segments/ContactTextSegment";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  contactSection: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 10vh 10vh",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("lg")]: {
      margin: "0 10vw 10vh",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "5vh 0 0",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "10vh auto",
    marginBottom: "5vh",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  segments: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    flexWrap: "nowrap",
    width: "100%",
  },
  disabledOnSmall: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  disabledOnBig: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

interface ContactSectionProps {}

export const ContactSection: React.FC<ContactSectionProps> = () => {
  const { classes } = useStyles();
  return (
    <div id="contact" className={classes.contactSection}>
      <Typography
        className={classes.title}
        sx={{ typography: { sm: "h3", xs: "h8" } }}
      >
        Contact
      </Typography>
      <AnimateComponentOnScroll enterDirection="bottom">
        <>
          <div className={classes.segments}>
            <div className={classes.disabledOnSmall}>
              <ContactTextSegment />
            </div>
            <ContactFormSegment />
          </div>
          <div className={classes.disabledOnBig}>
            <ContactTextSegment />
          </div>
        </>
      </AnimateComponentOnScroll>
    </div>
  );
};
