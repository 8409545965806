import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.footerItem,
    textDecoration: "none",
  },
  item: {
    display: "flex",
    color: theme.palette.footerItem,
    paddingBottom: "8px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "6px",
    },
  },
}));

interface FooterItemProps {
  title: string;
  link: string;
  target?: string;
  small?: boolean;
}

export const FooterItem: React.FC<FooterItemProps> = ({
  title,
  link,
  target,
  small,
}) => {
  const { classes } = useStyles();

  return (
    <Typography className={classes.item} variant={small ? "body6" : "body2"}>
      <a className={classes.link} href={link} target={target ?? "_self"}>
        {title}
      </a>
    </Typography>
  );
};
