import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  missionSection: {
    display: "flex",
    flexWrap: "wrap",
    margin: "10vh auto",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "0 auto",
    marginBottom: "5vh",
  },
  segment: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "60vw",
    flexWrap: "nowrap",
  },
  imageLeft: {
    maxWidth: "35%",
  },
  imageRight: {
    [theme.breakpoints.down("xl")]: {
      width: "400px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "320px",
    },
    [theme.breakpoints.down("md")]: {
      width: "250px",
    },
  },
  sectionTitle: {
    margin: "1vw 0",
  },
  section: {
    maxWidth: "50%",
  },
  text: {
    color: theme.palette.primary.light,
  },
}));

interface MissionSectionProps {}

export const MissionSection: React.FC<MissionSectionProps> = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.missionSection}>
      <Typography className={classes.title} variant={"h2"}>
        Our Mission
      </Typography>
      <AnimateComponentOnScroll enterDirection="right">
        <div className={classes.segment}>
          <img
            className={classes.imageLeft}
            src="images/mission-section-left.png"
            alt={"mission-section-1"}
          />
          <div className={classes.section}>
            <Typography className={classes.sectionTitle} variant={"h3"}>
              We are fully dedicated to the Chainlink ecosystem.
            </Typography>
            <Typography className={classes.text} variant={"subtitle1"}>
              No matter what network or product Chainlink integrates, we are on
              the front line to securely support them.
            </Typography>
          </div>
        </div>
      </AnimateComponentOnScroll>
      <AnimateComponentOnScroll enterDirection="left">
        <div className={classes.segment}>
          <div className={classes.section}>
            <Typography className={classes.sectionTitle} variant={"h3"}>
              Together we build a future powered by hybrid smart contracts.
            </Typography>
            <Typography className={classes.text} variant={"subtitle1"}>
              We support other Web3 infrastructure providers by developing
              tailor-made services for them.
            </Typography>
          </div>
          <img
            src="images/mission-section-right.png"
            alt={"mission-section-2"}
            className={classes.imageRight}
          />
        </div>
      </AnimateComponentOnScroll>
    </div>
  );
};
