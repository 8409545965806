import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { BlogSegment } from "./blog-segment/BlogSegment";
import { AnimateComponentOnScroll } from "../../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "10vh 10vw",
    textAlign: "center",
    background: "#F5F5F5",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: "5vh 10vw",
    },
  },
  title: {
    width: "100%",
  },
  description: {
    width: "100%",
    margin: "25px 0 50px",

    [theme.breakpoints.down("sm")]: {
      margin: "25px 0 50px",
    },
  },
  blogSegment: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    gap: "40px",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "75%",
    },
  },
  button: {
    display: "flex",
    width: "100%",
    height: "100%",
    margin: "0 42%",
    color: "white",
    fontSize: "25px",
    fontWeight: 500,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",
    background: "#012A4F",
    marginTop: "10vh",
    textTransform: "none",

    "&:hover": {
      background: "#000",
    },

    [theme.breakpoints.down("lg")]: {
      margin: "0 40%",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 35%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 30%",
      fontSize: "18px",
    },
  },
}));

interface BlogSectionProps {}

export const BlogSection: React.FC<BlogSectionProps> = () => {
  const { classes } = useStyles();

  return (
    <div id="blog" className={classes.root}>
      <Typography
        sx={{ typography: { sm: "h3", xs: "h8" } }}
        className={classes.title}
      >
        Blog
      </Typography>
      <Typography
        sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
        className={classes.description}
      >
        Read our articles where we provide helpful content for Chainlink node
        operators, validators and anyone else interested in Web3 infrastructure
        operations.
      </Typography>
      <AnimateComponentOnScroll
        enterDirection="bottom"
        display="flex"
        justifyContent="center"
      >
        <>
          <Box className={classes.blogSegment}>
            <BlogSegment
              thumbnail="blog-image-third"
              title="Launching the Decentralized Infrastructure Network with Infura, Microsoft, Tencent and Others"
              link="https://blog.linkriver.io/decentralized-infura/"
              description="An announcement about LinkRiver's partnership with leading Web2 and Web3 organizations"
              author={{
                image: "author-image-first",
                name: "LinkRiver",
                releaseDate: "15 November 2023",
                readTime: "4 min read",
              }}
            />
            <BlogSegment
              thumbnail="blog-image-second"
              title="ChainLinkGod Interview: A Chainlinked World 7 Years in the Future"
              link="https://blog.linkriver.io/chainlinkgod-interview/"
              description="Exclusive interview with a Chainlink Ambassador about Chainlink-enabled use cases that reach far into the future"
              author={{
                image: "author-image-first",
                name: "LinkRiver",
                releaseDate: "18 September 2023",
                readTime: "9 min read",
              }}
            />
            <BlogSegment
              thumbnail="blog-image-first"
              title="The Indispensable Guardians of the Web3 Economy: Chainlink Node Operators"
              link="https://blog.linkriver.io/chainlink-node"
              description="A case study on the development of LinkRiver in time lapse and the essential role of Chainlink nodes for the Web3 economy"
              author={{
                image: "author-image-first",
                name: "LinkRiver",
                releaseDate: "20 June 2023",
                readTime: "14 min read",
              }}
            />
          </Box>
        </>
      </AnimateComponentOnScroll>
      <Button
        href="https://blog.linkriver.io/"
        className={classes.button}
        target="_blank"
      >
        Go to Blog
      </Button>
    </div>
  );
};
