import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Slider } from "../../../../shared/slider/Slider";
import { SliderItem } from "../../../../shared/slider/slider-item/SliderItem";
import { AnimateComponentOnScroll } from "../../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "0 10vw 10vh",
    height: "100%",
    paddingTop: "10vh",

    [theme.breakpoints.down("sm")]: {
      margin: "5vh 10vw 10vh",
      paddingTop: "0",
    },
  },
  title: {
    width: "100%",
    textAlign: "center",
  },
  subTitle: {
    width: "100%",
    padding: "20px 15% 80px",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "20px 0 5vh 0",
    },
  },
  sliderBox: {
    width: "100%",
  },
}));

interface FeaturedSectionProps {}

export const FeaturedSection: React.FC<FeaturedSectionProps> = () => {
  const { classes } = useStyles();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const sliderItems = (
    <>
      <SliderItem
        title="We are a DIN launch partner together with Microsoft"
        link="https://consensys.io/blog/infura-partners-with-microsoft-chainstack-to-build-DIN"
        image="featured-fifth"
      />
      <SliderItem
        title="We love to contribute - watch our  panel at SmartCon #1"
        link="https://www.youtube.com/watch?v=jD9903qyt6w"
        image="featured-first"
      />
      <SliderItem
        title="We won a Chainlink grant to build a node operator debugging platform"
        link="https://blog.chain.link/linkriver-receives-chainlink-grant-for-node-operator-debugging-website/"
        image="featured-second"
      />
      <SliderItem
        title="We belong to the first cohort of the only Chainlink liquid staking platform"
        link="https://mirror.xyz/stakedotlink.eth/2etsmfEqf8kevocw4dVyjfFIAvGWlViaVKNDIa_Uaas"
        image="featured-third"
      />
      <SliderItem
        title="Get to know us in this Chainlink node operator spotlight interview"
        link="https://chainlinktoday.com/chainlink-node-operator-spotlight-get-to-know-linkriver/"
        image="featured-fourth"
      />
    </>
  );

  return (
    <Box id="featured" className={classes.root}>
      <Typography
        className={classes.title}
        sx={{ typography: { sm: "h3", xs: "h8" } }}
      >
        Featured
      </Typography>
      <Typography
        className={classes.subTitle}
        sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
      >
        Check out our contribution to the Chainlink ecosytem and how central
        figures of the Web3 economy report on LinkRiver.
      </Typography>
      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <Box className={classes.sliderBox}>
          <Slider
            id="scroll-featured"
            items={sliderItems}
            height={screenWidth < 600 ? "380px" : "430px"}
          />
        </Box>
      </AnimateComponentOnScroll>
    </Box>
  );
};
