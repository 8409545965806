import React from "react";
import { makeStyles } from "tss-react/mui";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Typography,
} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  listText: {
    display: "flex",
    flexWrap: "nowrap",
    paddingLeft: "20px",
    fontWeight: "600 !important",
    fontSize: "15px !important",
    color: theme.palette.primary.main,
  },
  servicesMenu: {
    display: "flex",
    flexWrap: "wrap",
    color: "#000",
    margin: "0",
    paddingLeft: "30px",
  },
}));

interface DropdownItemProps {
  link: string;
  text?: string;
  title?: string;
  target?: string;
  description?: string;
  children: React.ReactNode;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  text,
  link,
  title,
  target,
  description,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <ListItem disablePadding>
      <ListItemButton type="button" href={link} target={target ?? "_self"}>
        <ListItemIcon>{children}</ListItemIcon>
        {!(title && description) ? (
          <ListItemText className={classes.listText} primary={text} />
        ) : (
          <div className={classes.servicesMenu}>
            <Typography variant="body1">{title}</Typography>
            <Typography sx={{ lineHeight: "20px" }} variant="body3">
              {description}
            </Typography>
          </div>
        )}
      </ListItemButton>
    </ListItem>
  );
};
