import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  copyright: {
    color: theme.palette.footerItem,
    display: "flex",
    justifyContent: "center",
    whiteSpace: "pre-line",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      color: "white",
      background: "linear-gradient(90deg, #3278BE 0%, #45BE98 100%)",
      padding: "3px 0",
      marginBottom: "5px",
      backgroundSize: "120% 120%",
      animation: "gradient-animation 6s ease infinite",
      "@keyframes gradient-animation": {
        "0%": {
          backgroundPosition: "0% 50%",
        },
        "50%": {
          backgroundPosition: "100% 50%",
        },
        "100%": {
          backgroundPosition: "0% 50%",
        },
      },
    },
  },
}));

interface FooterCopyrightProps {
  small?: boolean;
}

export const FooterCopyright: React.FC<FooterCopyrightProps> = ({ small }) => {
  const { classes } = useStyles();

  return (
    <Typography
      className={classes.copyright}
      variant={small ? "body7" : "body4"}
    >
      © LinkRiver. 2023.{small ? <br /> : " "}Top-tier Chainlink node service
      provider
    </Typography>
  );
};
