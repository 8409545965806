import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  leftSegment: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around",
    width: "500px",
    margin: "10vh 0",
    padding: "10vh 5vw",
    borderRight: "1px solid #000",

    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
      border: "0",
      margin: "5vh 0 0",
      padding: "0 10vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 10vw",
    },
  },
  image: {
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      height: "23px",
    },
  },
  logos: {
    display: "flex",
    float: "left",
    marginTop: "5vh",

    [theme.breakpoints.down("sm")]: {
      height: "30px",
      alignItems: "center",
      margin: "5vh 5vw",
    },
  },
}));

interface ContactTextSegmentProps {}

export const ContactTextSegment: React.FC<ContactTextSegmentProps> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.leftSegment}>
      <Typography sx={{ typography: { sm: "body1", xs: "body5" } }}>
        Don’t hesitate to contact us, our team is highly available in order to
        get back to you as fast as possible. If you want to learn more about
        decentralized technologies, feel free to join us in one of our social
        channels.
      </Typography>
      <div className={classes.logos}>
        <a
          href="https://twitter.com/linkriver_io"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={classes.image}
            src="../images/twitter-blue.png"
            alt={"service"}
          />
        </a>
        <a
          href="https://www.linkedin.com/company/linkriver/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={classes.image}
            src="../images/linkedin-blue.png"
            alt={"service"}
          />
        </a>
      </div>
    </div>
  );
};
