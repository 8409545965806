import React from "react";
import { makeStyles } from "tss-react/mui";
import { DropdownItem } from "./dropdown-item/DropdownItem";
import { DropdownList } from "./dropdown-list/DropdownList";

const useStyles = makeStyles()((theme) => ({
  servicesImage: {
    width: "50px",
    padding: "10px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
  },
  image: {
    widht: "30px",
    height: "30px",
  },
}));

interface HeaderDropdownProps {
  transparent: boolean;
}

export const HeaderDropdown: React.FC<HeaderDropdownProps> = ({
  transparent,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <DropdownList
        title="Services"
        link="/#services"
        transparent={transparent}
      >
        <DropdownItem
          title="Consulting"
          description="We provide industry-leading Web3 consultancy services"
          link="/web3-consultancy"
        >
          <img
            className={classes.servicesImage}
            src="../images/header-services-second.png"
            alt="menu"
          />
        </DropdownItem>
        <DropdownItem
          title="NodeLogs"
          description="
            Chainlink node operator debugging platform"
          link="https://nodelogs.link/"
          target="_blank"
        >
          <img
            className={classes.servicesImage}
            src="../images/header-services-third.png"
            alt="menu"
          />
        </DropdownItem>
      </DropdownList>
      <DropdownList
        title="Resources"
        link="/resources"
        transparent={transparent}
      >
        <DropdownItem
          text="Blog"
          link="https://blog.linkriver.io/"
          target="_blank"
        >
          <img
            className={classes.image}
            src="../images/header-resources-second.png"
            alt="menu"
          />
        </DropdownItem>
        <DropdownItem text="Featured" link="/resources/#featured">
          <img
            className={classes.image}
            src="../images/header-resources-first.png"
            alt="menu"
          />
        </DropdownItem>
        <DropdownItem text="GitHub" link="/resources/#github">
          <img
            className={classes.image}
            src="../images/header-resources-third.png"
            alt="menu"
          />
        </DropdownItem>
        <DropdownItem text="Research" link="/resources/#research">
          <img
            className={classes.image}
            src="../images/header-resources-fifth.png"
            alt="menu"
          />
        </DropdownItem>
      </DropdownList>
      <DropdownList
        title="About"
        link="/about"
        reversed
        transparent={transparent}
      >
        <DropdownItem text="History" link="/about/#history">
          <img
            className={classes.image}
            src="../images/header-about-first.png"
            alt="menu"
          />
        </DropdownItem>
        <DropdownItem text="Contact" link="/about/#contact">
          <img
            className={classes.image}
            src="../images/header-about-second.png"
            alt="menu"
          />
        </DropdownItem>
      </DropdownList>
    </>
  );
};
