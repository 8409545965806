import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { AnimateComponentOnScroll } from "../../../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";
import { Slider } from "../../../../../shared/slider/Slider";
import { SliderItem } from "../../../../../shared/slider/slider-item/SliderItem";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "10vh 10vw",
  },
  title: {
    color: "#012A4F",
    marginBottom: "5vh",
    fontSize: "40px",
    fontWeight: 600,

    [theme.breakpoints.down("md")]: {
      fontSize: "38px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px",
    },
  },
  segmentBox: {
    display: "flex",
    width: "100%",
  },
}));

interface ContributorsSectionProps {
  background?: string;
}

export const ContributorsSection: React.FC<ContributorsSectionProps> = ({
  background,
}) => {
  const { classes } = useStyles();

  const sliderItems = (
    <>
      <SliderItem
        title="We love to contribute - watch our  panel at SmartCon #1"
        link="https://www.youtube.com/watch?v=jD9903qyt6w"
        image="featured-first"
      />
      <SliderItem
        title="We won a Chainlink grant to build a node operator debugging platform"
        link="https://blog.chain.link/linkriver-receives-chainlink-grant-for-node-operator-debugging-website/"
        image="featured-second"
      />
      <SliderItem
        title="We belong to the first cohort of the only Chainlink liquid staking platform"
        link="https://mirror.xyz/stakedotlink.eth/2etsmfEqf8kevocw4dVyjfFIAvGWlViaVKNDIa_Uaas"
        image="featured-third"
      />
      <SliderItem
        title="Get to know us in this Chainlink node operator spotlight interview"
        link="https://chainlinktoday.com/chainlink-node-operator-spotlight-get-to-know-linkriver/"
        image="featured-fourth"
      />
    </>
  );

  return (
    <AnimateComponentOnScroll enterDirection="bottom">
      <Box
        sx={{ background: background ?? "#FBFBFF" }}
        className={classes.root}
      >
        <h3 className={classes.title}>Who you will work with</h3>
        <Box className={classes.segmentBox}>
          <Slider id="scroll-featured" items={sliderItems} height={"430px"} />
        </Box>
      </Box>
    </AnimateComponentOnScroll>
  );
};
