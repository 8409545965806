import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Box, Button, Typography, Snackbar, Alert } from "@mui/material";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const useStyles = makeStyles()((theme) => ({
  background: {
    background: "linear-gradient(to right, #3278BE, #45BE98)",
    padding: "8vh 0 4vh 0",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: "6vh 0 0vh 0",
    },
  },
  grid: {
    display: "flex",
    padding: "0 10%",

    [theme.breakpoints.down("xl")]: {
      padding: "0 15%",
    },

    [theme.breakpoints.down("lg")]: {
      padding: "0 10%",
    },
  },
  textMain: {
    display: "flex",
    color: "white",
    marginBottom: "6px",
    lineHeight: 1,

    [theme.breakpoints.down("sm")]: {
      marginBottom: "6px",
    },
  },
  textSub: {
    display: "flex",
    color: "white",
    margin: "0 0 4vh 0",
    lineHeight: "1.2 !important",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  subscribeBox: {
    display: "flex",
    width: "100%",
    height: "5vh",
    margin: "2.5vh 8vh 0",
    borderRadius: "30px",

    [theme.breakpoints.down("xl")]: {
      margin: "0",
    },
    [theme.breakpoints.down("sm")]: {
      height: "6vh",
      margin: "5vh 0 0 0",
    },
  },
  emailBox: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  emailText: {
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    autoFocus: "false",
    width: "100%",
    outline: "none",
    border: "none",
    padding: "0 25px",
    borderTopLeftRadius: "30px",
    borderBottomLeftRadius: "30px",

    [theme.breakpoints.down("xl")]: {
      marginLeft: "0",
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      fontWeight: 500,
    },
  },
  emailButton: {
    display: "flex",
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    background: "#012A4F",

    "&:hover": {
      background: "#000",
    },
  },
  emailButtonText: {
    textTransform: "none",
    color: "white",

    [theme.breakpoints.down("sm")]: {},
  },

  alert: {
    fontSize: "18px",
    "& .MuiAlert-icon": {
      fontSize: "24px",
    },
  },

  consentText: {
    display: "flex",
    color: "white",
    margin: "1vh 0 2vh 10vh",
    fontSize: "15px",

    [theme.breakpoints.down("xl")]: {
      margin: "1vh 0 2vh 2vh",
    },

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      fontSize: "11px",
      margin: "1vh 0 2vh 0",
    },
  },
}));

export const Newsletter: React.FC<any> = ({ status, message, onValidated }) => {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openNegative, setOpenNegative] = React.useState(false);
  const [email, setEmail] = useState("");
  const { classes } = useStyles();

  const handleSubmit = () => {
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === "success") {
      setOpenSuccess(true);
    } else if (status === "error") {
      setOpenNegative(true);
    }
  }, [status]);

  return (
    <Box className={classes.background}>
      <Snackbar
        open={openSuccess}
        autoHideDuration={5000}
        onClose={() => setOpenSuccess(false)}
      >
        <Alert severity="success" className={classes.alert}>
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openNegative}
        autoHideDuration={5000}
        onClose={() => setOpenNegative(false)}
      >
        <Alert severity="error" className={classes.alert}>
          {message}
        </Alert>
      </Snackbar>
      <Grid className={classes.grid} container spacing={0}>
        <Grid item xs={12} xl={5}>
          <Typography
            sx={{ typography: { sm: "h3", xs: "h8" } }}
            className={classes.textMain}
          >
            Subscribe to the LinkRiver Newsletter
          </Typography>
          <Typography
            sx={{ typography: { sm: "subtitle1", xs: "subtitle4" } }}
            className={classes.textSub}
          >
            Stay informed about the most relevant topics for Chainlink node
            operators, blockchain validators and anyone else interested in the
            Web3 infrastructure business.
          </Typography>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Box className={classes.subscribeBox}>
            <Grid container spacing={0}>
              <Grid item xl={10} lg={10} md={10} sm={9} xs={8}>
                <Box className={classes.emailBox}>
                  <input
                    className={classes.emailText}
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={3} xs={4}>
                <Button className={classes.emailButton} onClick={handleSubmit}>
                  <Typography className={classes.emailButtonText}>
                    Subscribe
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography className={classes.consentText}>
              By clicking “Subscribe” you agree to receive emails from
              LinkRiver.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export const NewsletterSubscribe = () => {
  const MAILCHIMP_URL =
    "https://linkriver.us21.list-manage.com/subscribe/post?u=cc9129153912bb70e7731b97a&amp;id=c918e9cd63&amp;f_id=00a55be1f0";

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={({ subscribe, status, message }) => (
        <Newsletter
          status={status}
          message={message}
          onValidated={(formData: any) => subscribe(formData)}
        />
      )}
    />
  );
};

export default NewsletterSubscribe;
