import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  serviceSection: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "50px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  header: {
    color: theme.palette.primary.main,
    margin: "0 25px",
    padding: "0 7px",
    marginBottom: "2vh",
    background: "#F5F5F5",
    borderRadius: "12px",
  },
  rightSegment: {
    width: "100%",
    display: "flex",
    height: "250px",
    backgroundColor: theme.palette.background3,
    borderRadius: "295px 0 0 295px",
    alignItems: "center",
    marginBottom: "40px",
  },
  leftSegment: {
    width: "100%",
    display: "flex",
    height: "250px",
    backgroundColor: theme.palette.background3,
    borderRadius: "0 295px 295px 0",
    alignItems: "center",
    marginBottom: "40px",
  },
  right: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end",
    textAlign: "end",
  },
  left: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    textAlign: "start",
  },
  subtitle: {
    padding: "0 25px",
  },
  description: {
    padding: "10px 25px 30px",
  },
  leftImage: {
    paddingLeft: "40px",
    maxHeight: "100px",
  },
  rightImage: {
    paddingRight: "40px",
    maxHeight: "100px",
  },
  button: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.background2,
    borderRadius: "43px",
    textTransform: "none",
    height: "40px",
    minWidth: "130px",
    padding: "0 24px 4px 24px",
    margin: "0 25px",
  },
}));

interface ServiceSectionMProps {}

export const ServiceSectionM: React.FC<ServiceSectionMProps> = () => {
  const { classes } = useStyles();

  return (
    <div id="services" className={classes.serviceSection}>
      <Typography className={classes.header} variant={"body6"}>
        Our Services
      </Typography>

      <div className={classes.leftSegment}>
        <div className={classes.left}>
          <Typography className={classes.subtitle} variant={"h8"}>
            Consulting
          </Typography>
          <Typography className={classes.description} variant={"body5"}>
            Succeed in the wild waters of decentralized technologies by gaining
            knowledge from industry experts providing unique Web3 consultancy
            services
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            href={"/web3-consultancy"}
          >
            <Typography variant={"button3"}>Learn more</Typography>
          </Button>
        </div>
        <img
          className={classes.rightImage}
          src="images/service-section-second.png"
          alt={"service"}
        />
      </div>

      <div className={classes.rightSegment}>
        <img
          className={classes.leftImage}
          src="images/service-section-third.png"
          alt={"service"}
        />
        <div className={classes.right}>
          <Typography className={classes.subtitle} variant={"h8"}>
            NodeLogs
          </Typography>
          <Typography className={classes.description} variant={"body5"}>
            A debugging platform for Chainlink node operators powered by a
            Chainlink grant
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            href={"https://nodelogs.link/"}
            target={"_blank"}
          >
            <Typography variant={"button3"}>Learn more</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};
