/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import axios from "axios";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  achievementsSection: {
    background: theme.palette.background2,
    display: "flex",
    flexWrap: "wrap",
    margin: "10vh auto 0",
    justifyContent: "center",
    color: theme.palette.primary.contrastText,
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subTitle: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "10vh auto 5vh auto",
    marginBottom: "1vh",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "0 3vw 4vh 3vw",
  },
  statSection: {
    display: "flex",
    flexWrap: "nowrap",
    width: "80vw",
    justifyContent: "space-around",
    textAlign: "center",
    marginBottom: "10vh",
  },
  statPortion: {
    width: "300px",
  },
}));

interface AchievementsSectionProps {}

export const AchievementsSection: React.FC<AchievementsSectionProps> = () => {
  const { classes } = useStyles();

  const [statistics, setStatistics] = useState({
    dataFeed: 0,
    networks: 0,
    transactions: 0,
  });

  useEffect(() => {
    if (statistics.dataFeed === 0) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/GetStatistics`)
        .then((res) => {
          const result = {
            dataFeed: res.data[0].unique_Data_Feeds,
            networks: res.data[0].supported_Networks,
            transactions: res.data[0].total_Transactions,
          };

          setStatistics(result);
        });
    }
  }, []);

  const delay = 1500;
  const { dataFeed, networks, transactions } = statistics;

  let dataFeedTimer: any;
  let networksTimer: any;
  let transactionsTimer: any;

  const dataFeedTime = Math.floor(delay / dataFeed);
  const networksTime = delay / networks;
  const transactionsTime = delay / transactions;

  const [startCounting, setStartCounting] = useState(false);
  const [dataFeedCounter, setDataFeed] = useState(0);
  const [networksCounter, setNetworks] = useState(0);
  const [transactionsCounter, setTransactions] = useState(0);

  const getCounter = (
    timer: any,
    time: number,
    data: number,
    setData: (arg0: (prev: any) => any) => void,
    counter: number
  ) => {
    if (!startCounting) return;
    clearInterval(timer);
    timer = setInterval(() => {
      if (counter >= data) {
        clearInterval(timer);
        return;
      }
      setData((prev) => prev + 1);
    }, time);

    return () => clearInterval(timer);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (1600 <= window.pageYOffset && window.pageYOffset <= 2400) {
          setStartCounting(true);
        } else {
          setStartCounting(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    return getCounter(
      dataFeedTimer,
      dataFeedTime,
      dataFeed,
      setDataFeed,
      dataFeedCounter
    );
  }, [dataFeedCounter, startCounting]);

  useEffect(() => {
    return getCounter(
      networksTimer,
      networksTime,
      networks,
      setNetworks,
      networksCounter
    );
  }, [networksCounter, startCounting]);

  useEffect(() => {
    return getCounter(
      transactionsTimer,
      transactionsTime,
      transactions,
      setTransactions,
      transactionsCounter
    );
  }, [transactionsCounter, startCounting]);

  return (
    <AnimateComponentOnScroll enterDirection="bottom">
      <div className={classes.achievementsSection}>
        <Typography className={classes.subTitle} variant={"subtitle1"}>
          Achievements
        </Typography>
        <Typography className={classes.title} variant={"h3"}>
          Our contribution to an increasingly decentralized world
        </Typography>
        <div className={classes.statSection}>
          <div className={classes.statPortion}>
            <Typography variant={"statNumber1"}>{dataFeedCounter}</Typography>
            <Typography variant={"body1"}>Unique data feeds</Typography>
          </div>
          <div className={classes.statPortion}>
            <Typography variant={"statNumber1"}>{networksCounter}</Typography>
            <Typography variant={"body1"}>Supported networks</Typography>
          </div>
          <div className={classes.statPortion}>
            <Typography
              variant={"statNumber1"}
            >{`${transactionsCounter}M +`}</Typography>
            <Typography variant={"body1"}>On-chain transactions</Typography>
          </div>
        </div>
      </div>
    </AnimateComponentOnScroll>
  );
};
