import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { ServiceSectionSegment } from "./service-section-segment/ServiceSectionSegment";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  serviceSection: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto 10vh",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "10vh auto",
    marginBottom: "5vh",
  },
}));

interface ServiceSectionProps {}

export const ServiceSection: React.FC<ServiceSectionProps> = () => {
  const { classes } = useStyles();
  return (
    <div id="services" className={classes.serviceSection}>
      <Typography className={classes.title} variant={"h2"}>
        Our Services
      </Typography>

      <AnimateComponentOnScroll enterDirection="left" width="100%">
        <ServiceSectionSegment
          title="Consulting"
          link="/web3-consultancy"
          image="service-section-second"
          reversed={true}
        >
          Succeed in the wild waters of decentralized technologies by gaining
          knowledge from industry experts providing unique Web3 consultancy
          services
        </ServiceSectionSegment>
      </AnimateComponentOnScroll>

      <AnimateComponentOnScroll enterDirection="right" width="100%">
        <ServiceSectionSegment
          title="NodeLogs"
          link="https://nodelogs.link/"
          newSide
          image="service-section-third"
        >
          A debugging platform for Chainlink node operators powered by a
          Chainlink grant. From the community for the community.
        </ServiceSectionSegment>
      </AnimateComponentOnScroll>
    </div>
  );
};
