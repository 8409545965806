import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { HistorySegment } from "./segments/HistorySegment";
import { AnimateComponentOnScroll } from "../../../shared/animate-component-on-scroll/AnimateComponentOnScroll";

const useStyles = makeStyles()((theme) => ({
  historySection: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 3vw 0",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      margin: "5vh 0",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100vw",
    margin: "10vh auto",
    marginBottom: "5vh",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  launching: {
    display: "flex",
    width: "100%",
    margin: "0 100px 100px",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      margin: "0 10px 20px",
    },
  },
  developing: {
    display: "flex",
    width: "100%",
    margin: "0 100px 0",

    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      margin: "0 10px 20px",
    },
  },
  evolving: {
    display: "flex",
    width: "100%",
    margin: "0 100px 100px",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
      margin: "0 10px 20px",
    },
  },
  launchingText: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around",
    margin: "0 0 0 80px",

    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "0 25px",
    },
  },
  launchingImage: {
    height: "370px",

    [theme.breakpoints.down("lg")]: {
      margin: "auto",
      width: "250px",
      height: "250px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "250px",
      height: "250px",
    },
  },
  evolvingText: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around",
    margin: "0 40px",
    height: "800px",

    [theme.breakpoints.down("lg")]: {
      margin: "0",
      alignContent: "start",
      gap: "25px",
      height: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0",
      height: "fit-content",
      gap: "0",
    },
  },
  evolvingImages: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    [theme.breakpoints.down("xl")]: {
      "& > img": {
        width: "400px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  evolvingImagesBox: {
    display: "flex",
    flexWrap: "wrap",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "space-around",
    },
  },
  evolvingImagesSmall: {
    display: "flex",
    width: "270px",
    margin: "0 auto",

    [theme.breakpoints.down("lg")]: {
      width: "50%",
      marginBottom: "25px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "80%",
      marginBottom: "25px",
    },
  },
  evolvingFirstParagraph: {
    width: "800px",
    marginBottom: "100px",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0",
      padding: "25px",
    },
  },
  evolvingSecondParagraph: {
    display: "flex",
    flexWrap: "nowrap",

    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
  paragraph: {
    padding: "0 25px 25px",

    [theme.breakpoints.down("lg")]: {
      padding: "0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 25px 25px",
    },
  },
  developingText: {
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      marginBottom: "100px",
    },
  },
  developingIcon: {
    marginTop: "-150px",
    width: "70%",

    [theme.breakpoints.down("lg")]: {
      marginTop: "-100px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  developingIconSmall: {
    width: "80%",
    margin: "-60px auto 0",

    [theme.breakpoints.down("md")]: {
      marginTop: "-20px",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

interface HistorySectionProps {}

export const HistorySection: React.FC<HistorySectionProps> = () => {
  const { classes } = useStyles();
  return (
    <div id="history" className={classes.historySection}>
      <Typography
        className={classes.title}
        sx={{ typography: { sm: "h3", xs: "subtitle1" } }}
      >
        Our History
      </Typography>

      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <HistorySegment
          year="2020"
          title="Launching"
          yearColor="#3278BE"
          backgroundColor="#3278BE0D"
        >
          <div className={classes.launching}>
            <img
              className={classes.launchingImage}
              src="../images/history-launching.png"
              alt="launching"
            />
            <div className={classes.launchingText}>
              <Typography
                sx={{
                  paddingBottom: "30px",
                  paddingTop: { md: "0", xs: "30px" },
                  typography: { sm: "subtitle1", xs: "body5" },
                }}
              >
                As longtime members of the Chainlink community, the LinkRiver
                founders decided to launch their own node operating business
                while following the SmartCon 0 in 2020.
              </Typography>
              <Typography sx={{ typography: { sm: "subtitle1", xs: "body5" } }}>
                During the Spring Chainlink Virtual Hackathon, they helped
                various teams create custom Chainlink solutions for their
                projects in order to power smart contracts with real-world data
                and off-chain computation.
              </Typography>
            </div>
          </div>
        </HistorySegment>
      </AnimateComponentOnScroll>
      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <HistorySegment
          year="2021"
          title="Evolving"
          yearColor="#45BE98"
          backgroundColor="#45BE980D"
        >
          <div className={classes.evolving}>
            <div className={classes.evolvingImagesBox}>
              <img
                className={classes.evolvingImagesSmall}
                src="../images/history-evolving-first.png"
                alt="evolving"
              />
              <img
                className={classes.evolvingImagesSmall}
                src="../images/history-evolving-second.png"
                alt="evolving"
              />
            </div>
            <Typography
              className={classes.evolvingFirstParagraph}
              sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
            >
              Shorly thereafter, the team became aware of the first ever Oracle
              Olympics and registered, competed and eventually won the
              competition, which was followed by the onboarding into Chainlink's
              official Decentralized Oracle Networks.
            </Typography>
            <div className={classes.evolvingSecondParagraph}>
              <div className={classes.evolvingImages}>
                <img
                  src="../images/history-evolving-first.png"
                  alt="evolving"
                />
                <img
                  src="../images/history-evolving-second.png"
                  alt="evolving"
                />
              </div>
              <div className={classes.evolvingText}>
                <Typography
                  className={classes.paragraph}
                  sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
                >
                  Since then, LinkRiver has helped secure Chainlink's{" "}
                  <a
                    href="https://data.chain.link/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Feeds
                  </a>
                  ,{" "}
                  <a
                    href="https://chain.link/proof-of-reserve"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Proof of Reserve
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://chain.link/automation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Automation
                  </a>{" "}
                  across a variety of networks.
                </Typography>
                <Typography
                  className={classes.paragraph}
                  sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
                >
                  The Co-founders have been invited to join the first cohort of
                  the{" "}
                  <a
                    href="https://chain.link/developers/experts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Developer Expert Program
                  </a>{" "}
                  initiated by Chainlink Labs, following the goal to help
                  aspiring blockchain developers learn how to write smart
                  contracts and integrate Chainlink products.
                </Typography>
                <Typography
                  className={classes.paragraph}
                  sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
                >
                  In September 2021, they held their{" "}
                  <a
                    href="https://www.youtube.com/watch?v=jD9903qyt6w"
                    target="_blank"
                    rel="noreferrer"
                  >
                    own panel
                  </a>{" "}
                  during SmartCon 1, where they answered frequently asked
                  questions from the node operator community and gave advice on
                  how to become a successful Chainlink node operator.
                </Typography>
              </div>
            </div>
          </div>
        </HistorySegment>
      </AnimateComponentOnScroll>
      <AnimateComponentOnScroll enterDirection="bottom" width="100%">
        <HistorySegment
          year="2022"
          title="Developing"
          yearColor="#5F5F5F"
          backgroundColor="#5F5F5F0D"
        >
          <div className={classes.developing}>
            <div className={classes.developingText}>
              <img
                className={classes.developingIconSmall}
                src="../images/history-developing.png"
                alt="developing"
              />
              <Typography
                className={classes.paragraph}
                sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
              >
                LinkRiver also received a{" "}
                <a
                  href="https://blog.chain.link/linkriver-receives-chainlink-grant-for-node-operator-debugging-website/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Chainlink Community Grant
                </a>{" "}
                to build{" "}
                <a
                  href="https://nodelogs.link/"
                  target="_blank"
                  rel="noreferrer"
                >
                  nodelogs.link
                </a>
                , a debugging platform for Chainlink node operators, which went
                live in January 2022. The team's focus is on strengthening the
                Chainlink network by developing ecosystem tools and offering
                services that facilitate node operations by abstracting complex
                processes, thus lowering the barrier to entry for new node
                service providers and improving the performance of existing
                ones.
              </Typography>
              <Typography
                className={classes.paragraph}
                sx={{ typography: { sm: "subtitle1", xs: "body5" } }}
              >
                From the beginning, LinkRiver's vision has been to provide free,
                high-quality education on decentralized technologies and to
                reach the widest possible number of people from a wide variety
                of backgrounds and educational levels.
              </Typography>
              <img
                className={classes.developingIcon}
                src="../images/history-developing.png"
                alt="developing"
              />
            </div>
          </div>
        </HistorySegment>
      </AnimateComponentOnScroll>
    </div>
  );
};
