import React from "react";
import { makeStyles } from "tss-react/mui";
import { Typography } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  missionSection: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "50px",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  header: {
    color: theme.palette.primary.main,
    margin: "0 25px",
    padding: "0 7px",
    marginBottom: "2vh",
    background: "#F5F5F5",
    borderRadius: "12px",
  },
  imageBox: {
    position: "relative",
    width: "100vw",
  },
  firstSegment: {
    overflow: "hidden",
    marginLeft: "25px",
  },
  imageRight: {
    maxHeight: "300px",
    position: "absolute",
    top: "3vh",
    right: "-14vw",
  },
  titleLeft: {
    ...theme.typography.h8,
  },
  textLeft: {
    margin: "50px 0",
    color: theme.palette.primary.light,
    ...theme.typography.body5,
  },
  secondSegment: {
    overflow: "hidden",
    marginRight: "25px",
  },
  imageLeft: {
    maxHeight: "270px",
    position: "absolute",
    top: "4vh",
    left: "-14vw",
  },
  titleRight: {
    ...theme.typography.h8,
    textAlign: "end",
  },
  textRight: {
    ...theme.typography.body5,
    margin: "50px 0",
    textAlign: "end",
  },
}));

interface MissionSectionMProps {}

export const MissionSectionM: React.FC<MissionSectionMProps> = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.missionSection}>
      <Typography className={classes.header} variant={"body6"}>
        Our Mission
      </Typography>

      <div className={classes.firstSegment}>
        <div className={classes.imageBox}>
          <img
            className={classes.imageRight}
            src="images/mission-section-left.png"
            alt={"mission-section-1"}
          />
        </div>
        <div className={classes.titleLeft}>
          We are fully <br />
          dedicated to the <br />
          Chainlink <br />
          ecosystem.
        </div>
        <div className={classes.textLeft}>
          No matter what <br />
          network or product <br />
          Chainlink integrates, we <br />
          are on the front line to <br />
          securely support them.
        </div>
      </div>

      <div className={classes.secondSegment}>
        <div className={classes.imageBox}>
          <img
            className={classes.imageLeft}
            src="images/mission-section-right.png"
            alt={"mission-section-2"}
          />
        </div>
        <div className={classes.titleRight}>
          Together we build a <br />
          future powered by <br />
          hybrid smart <br />
          contracts.
        </div>
        <div className={classes.textRight}>
          We support other <br />
          Web3 infrastructure <br />
          providers by developing <br />
          tailor-made services for them.
        </div>
      </div>
    </div>
  );
};
