import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { SwipeableDrawer } from "@mui/material";
import { OpenButton } from "./drawer-buttons/OpenButton";
import { CloseButton } from "./drawer-buttons/CloseButton";
import { DrawerList } from "./drawer-list/DrawerList";
import { DrawerItem } from "./drawer-item/DrawerItem";

const useStyles = makeStyles()((theme) => ({
  drawerContent: {
    margin: "0 40px 0 30px",
  },
  drawerList: {
    marginTop: "86.5px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
  },
  servicesImage: {
    widht: "30px",
    height: "30px",
    padding: "6px",
    boxShadow: "0px 4px 12px rgb(0 0 0 / 12%)",
  },
  image: {
    widht: "20px",
    height: "20px",
  },
}));

interface HeaderDrawerProps {
  transparent: boolean;
}

export const HeaderDrawer: React.FC<HeaderDrawerProps> = ({ transparent }) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <OpenButton onClick={() => setOpen(!open)} transparent={transparent} />
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={() => setOpen(!open)}
        onOpen={() => setOpen(!open)}
      >
        <CloseButton onClick={() => setOpen(!open)} />
        <div className={classes.drawerContent}>
          <DrawerList title="Services">
            <DrawerItem title="Consulting" link="/web3-consultancy">
              <img
                className={classes.servicesImage}
                src="../images/header-services-second.png"
                alt="menu"
              />
            </DrawerItem>
            <DrawerItem
              title="NodeLogs"
              link="https://nodelogs.link/"
              target="_blank"
            >
              <img
                className={classes.servicesImage}
                src="../images/header-services-third.png"
                alt="menu"
              />
            </DrawerItem>
          </DrawerList>
          <DrawerList title="Resources">
            <DrawerItem
              title="Blog"
              link="https://blog.linkriver.io/"
              target="_blank"
            >
              <img
                className={classes.image}
                src="../images/header-resources-second.png"
                alt="menu"
              />
            </DrawerItem>
            <DrawerItem title="Featured" link="/resources/#featured">
              <img
                className={classes.image}
                src="../images/header-resources-first.png"
                alt="menu"
              />
            </DrawerItem>
            <DrawerItem title="GitHub" link="/resources/#github">
              <img
                className={classes.image}
                src="../images/header-resources-third.png"
                alt="menu"
              />
            </DrawerItem>
            {process.env.REACT_APP_NODE_OPERATOR_MASTER_CLASS === "true" && (
              <DrawerItem
                title="Node Operator Masterclass"
                link="/resources/#node-operator-masterclass"
              >
                <img
                  className={classes.image}
                  src="../images/header-resources-fourth.png"
                  alt="menu"
                />
              </DrawerItem>
            )}
            <DrawerItem title="Research" link="/resources/#research">
              <img
                className={classes.image}
                src="../images/header-resources-fifth.png"
                alt="menu"
              />
            </DrawerItem>
          </DrawerList>
          <DrawerList title="About">
            <DrawerItem title="History" link="/about/#history">
              <img
                className={classes.image}
                src="../images/header-about-first.png"
                alt="menu"
              />
            </DrawerItem>
            <DrawerItem title="Contact" link="/about/#contact">
              <img
                className={classes.image}
                src="../images/header-about-second.png"
                alt="menu"
              />
            </DrawerItem>
          </DrawerList>
        </div>
      </SwipeableDrawer>
    </>
  );
};
